<template>
	<div class="pdf">
		<van-nav-bar
		class="nav_bar_color"
		title="pdf预览"
		left-text="返回"
		left-arrow
		@click-left="onClickLeft"
		fixed
		z-index="11"
		placeholder 
		:border="false"
		>
	</van-nav-bar>
		<pdf style="width: 100%;height: 80%;" ref="pdf" :src='src' :page="1" ></pdf>
	</div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
	components: {
		pdf
	},
  data() {
	return {
	  src: '',
	}
  },
  mounted() {
		this.src = this.$route.query.contractUrl
  }
}
</script>

<style lang="scss" scoped>
.pdf{
	height: 100%;
	width: 100%;
}

</style>
